import './ImageContent.css';
import carerImg from '../../../../images/career-img.png';
import carerImgMobile from '../../../../images/career-img-mobile.png';
import { useEffect, useState } from 'react';

const ImageContent = () => {
  const [mapSrc, setMapSrc] = useState(carerImg);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setMapSrc(carerImgMobile);
      } else {
        setMapSrc(carerImg);
      }
    };

    // Initial check on mount
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="image-content-container">
     
    </div>
  );
}

export default ImageContent;