import interna from '../../images/services/interna-medicina.png';
import kardiologija from '../../images/services/kardiologija.png';
import endokrinologija from '../../images/services/endokrinologija.png';
import nefrologija from '../../images/services/nefrologija.png';
import reumatologija from '../../images/services/reumatologija.png';
import hematologija from '../../images/services/hematologija.png';
import aritmologija from '../../images/services/aritmologija.png';
import onkologija from '../../images/services/onkologija.png';
import opstaH from '../../images/services/opsta-hirurgija.png';
import vaskularna from '../../images/services/vaskularna-h.png';
import ortopedija from '../../images/services/ortopedija.png';
import urologija from '../../images/services/urologija.png';
import radiologija from '../../images/services/radiologija.png';
import anestezija from "../../images/services/anestezija.png";
import gastro from '../../images/services/gastro.png';
import ServiceContent from './components/ServiceContent/ServiceContent';
import './Service.css';
import sanjaD from '../../images/team/sanja-dabic.png'
import snezanaG from '../../images/team/snezana-glisic.png'
import milicaL from '../../images/team/milica-lovric.png'
import daliborkaS from '../../images/team/daliborka-spasojevic.png'
import biljanaM from '../../images/team/biljana-marjanovic.png'
import slobodanL from '../../images/team/slobodan-lovric.png'
import milankoM from '../../images/team/milanko-maksic.png'
import stankoL from '../../images/team/stansko-lazarevic.png'
import srdjanP from '../../images/team/srdjan-popovic.png';
import dejanK from '../../images/team/dejan-kojic.png';

const Service = () => {

  const serviceItems = [
    { serviceName: "Interna medicina", serviceImage: interna, doctorImage: snezanaG, doctorName: "Prim. Dr Snežana Glišić", profession: "internista", content: 'Interna medicina je grana medicine koja se bavi prevencijom, dijagnostikom i liječenjem bolesti unutrašnjih organa kod odraslih osoba. Ljekari internisti fokusiraju se na širok spektar bolesti koje pogađaju organske sisteme kao što su srce, pluća, jetra, bubrezi, digestivni trakt, i endokrini sistem. \n Internisti se bave hroničnim stanjima poput hipertenzije, dijabetesa, bolesti srca, kao i akutnim problemima poput infekcija ili respiratornih tegoba. Njihova uloga je ključna u pružanju sveobuhvatne njege pacijentima, često koordinirajući sa specijalistima drugih oblasti.'},
    { serviceName: "Kardiologija", serviceImage: kardiologija, doctorImage: milicaL, doctorName: "Prim. Dr Milica Lovrić", profession: "internista- kardiolog", content: 'Kardiologija je grana medicine koja se bavi dijagnostikom i liječenjem bolesti srca i krvnih sudova. Kardiolozi se fokusiraju na stanja poput koronarne bolesti srca, srčane insuficijencije, aritmija, hipertenzije i srčanih mana. Koriste različite dijagnostičke metode, uključujući EKG, ultrazvuk srca, stres testove i kateterizaciju srca, kako bi procijenili funkciju srca i identificirali probleme. \n Liječenje može uključivati promjene u načinu života, farmakološku terapiju, kao i invazivne procedure poput angioplastike ili hirurških zahvata. Kardiologija igra ključnu ulogu u prevenciji srčanih bolesti i unapređenju zdravlja kardiovaskularnog sistema.'},
    { serviceName: "Vaskularna hirurgija", serviceImage: vaskularna, doctorImage: milankoM, doctorName: "Prof. Dr Milanko Maksić", profession: "vaskularni hirurg", content: 'Vaskularna hirurgija je grana medicine koja se bavi dijagnostikom i liječenjem bolesti krvnih sudova, uključujući arterije, vene i limfni sistem. Vaskularni hirurzi tretiraju stanja kao što su aneurizme, proširene vene, periferna arterijska bolest, tromboza i začepljenja krvnih sudova. \n Liječenje u vaskularnoj hirurgiji može uključivati konzervativne pristupe, poput promjena u načinu života i farmakoterapije, kao i invazivne procedure, kao što su hirurške operacije, angioplastika ili ugradnja stentova. Vaskularni hirurzi koriste napredne dijagnostičke tehnike, uključujući ultrazvuk, CT i MRI skeniranje, kako bi utvrdili stanje krvnih sudova. Cilj vaskularne hirurgije je poboljšanje cirkulacije, prevencija ozbiljnih komplikacija kao što su moždani udar ili amputacije, i unapređenje kvaliteta života pacijenata sa vaskularnim bolestima'},
    { serviceName: "Onkologija", serviceImage: onkologija, doctorImage: biljanaM, doctorName: "Prim. Dr Biljana Marjanović", profession: "internista- onkolog", content: 'Onkologija je grana medicine koja se bavi dijagnostikom, liječenjem i prevencijom raka. Onkolozi specijalizovani su za različite vrste tumora, uključujući solidne tumore (kao što su karcinomi pluća, dojke i debelog crijeva) i hematološke maligne bolesti (kao što su leukemija i limfom). \n Liječenje raka može uključivati hirurške zahvate, hemoterapiju, radioterapiju, imunoterapiju i ciljanje terapije, u zavisnosti od vrste i stadijuma bolesti. Onkolozi rade u timu sa drugim stručnjacima, poput radiologa, patologa i onkoloških medicinskih sestara, kako bi pružili sveobuhvatnu njegu pacijentima. Osim liječenja, onkologija se takođe fokusira na podršku pacijentima tokom procesa liječenja, uključujući upravljanje simptomima i poboljšanje kvaliteta života.'},
    { serviceName: "Reumatologija", serviceImage: reumatologija, doctorImage: daliborkaS, doctorName: "Dr Daliborka Spasojević", profession: "internista- reumatolog", content: 'Reumatologija je grana medicine koja se bavi dijagnostikom i liječenjem reumatskih bolesti, koje obuhvataju širok spektar poremećaja vezivnog tkiva i zglobova. Reumatolozi se fokusiraju na stanja kao što su osteoartritis, reumatoidni artritis, lupus, ankilozantni spondilitis i giht. Ove bolesti mogu uzrokovati bol, ukočenost, otok i smanjenu pokretljivost zglobova, a takođe mogu uticati i na druge organe. \n Liječenje u reumatologiji često uključuje kombinaciju farmakološke terapije, fizioterapije i promjena u načinu života kako bi se smanjili simptomi i poboljšala kvaliteta života pacijenata. Reumatologija igra ključnu ulogu u praćenju i upravljanju hroničnim stanjima koja mogu značajno uticati na svakodnevno funkcionisanje.'},
    { serviceName: "Endokrinologija", serviceImage: endokrinologija, doctorImage: srdjanP, doctorName: "Prof. dr Srđan Popović", profession: "endokrinolog", content: 'Endokrinologija je grana medicine koja se bavi proučavanjem hormona, hormonskih žlijezda i poremećaja povezanih s endokrinim sistemom. Endokrinolozi dijagnostikuju i liječe različite bolesti i stanja, uključujući dijabetes, bolesti štitne žlijezde (poput hipotireoze i hipertireoze), poremećaje rasta, adrenalne bolesti, kao i hormonalne poremećaje poput sindroma policističnih jajnika. \n Endokrinologija se oslanja na različite dijagnostičke metode, kao što su krvne analize za mjerenje nivoa hormona, slikovne tehnike i biopsije. Liječenje može uključivati hormonsku terapiju, promjene u načinu života, kao i hirurške zahvate u slučajevima tumora ili drugih abnormalnosti. Cilj endokrinologije je obnavljanje ravnoteže hormona u tijelu i upravljanje hroničnim stanjima koja mogu značajno uticati na zdravlje i kvalitet života pacijenata.'},
    { serviceName: "Opšta hirurgija", serviceImage: opstaH, doctorImage: slobodanL, doctorName: "Prim. Dr Slobodan Lovrić", profession: "opšti hirurg", content: 'Opšta hirurgija je grana medicine koja se bavi dijagnostikom i hirurškim liječenjem različitih bolesti i povreda, prvenstveno u abdomenu, ali i u drugim dijelovima tijela. Opšti hirurzi izvode razne hirurške zahvate, uključujući operacije slepog creva, žučne kese, hernija, kao i liječenje povreda i infekcija. \n Ova disciplina obuhvata i hitne hirurške intervencije kod traumatskih povreda, kao i elektivne operacije. Hirurzi u opštoj hirurgiji koriste moderne tehnike, uključujući minimalno invazivne metode poput laparoskopije, koje omogućavaju brži oporavak pacijenata. Opšta hirurgija takođe igra ključnu ulogu u postavljanju dijagnoze i terapiji raznih stanja, te često sarađuje sa drugim specijalistima kako bi se obezbijedila sveobuhvatna njega pacijenata.'},
    { serviceName: "Radiologija", serviceImage: radiologija, doctorImage: stankoL, doctorName: "Dr Stanko Lazarević", profession: "radiolog", content: 'Radiologija je grana medicine koja se bavi dijagnostikom i liječenjem bolesti korištenjem medicinskih slikovnih tehnika. Radiolozi koriste različite metode, uključujući rendgenske snimke, ultrazvuk, kompjuterizovanu tomografiju (CT), magnetnu rezonancu (MRI) i nuklearnu medicinu, kako bi procijenili unutrašnje organe, tkiva i strukture tijela. \n Ova disciplina igra ključnu ulogu u postavljanju dijagnoza, praćenju bolesti i evaluaciji terapijskog odgovora. Osim dijagnostičkih funkcija, radiologija obuhvata i intervencijske procedure, kao što su biopsije, drenaže apscesa i druge minimalno invazivne hirurške tehnike, koje se izvode pod kontrolom slikovnih metoda. Cilj radiologije je pružiti precizne i pravovremene informacije kako bi se unaprijedila briga o pacijentima i poboljšali ishodi liječenja.'},
    { serviceName: "Anestezija", serviceImage: anestezija, doctorImage: sanjaD, doctorName: "Prim. Dr Sanja Dabić", profession: "anesteziolog", content: 'Anesteziologija je grana medicine koja se bavi primjenom anestezije i upravljanjem bolom tokom hirurških i drugih medicinskih procedura. Anesteziolozi su specijalisti koji se fokusiraju na osiguranje sigurnosti pacijenata tokom operacija, pružajući odgovarajuću anesteziju, monitoring vitalnih funkcija i postoperativnu njegu. \n Anestezija može biti opšta, koja potpuno uspava pacijenta, ili regionalna, koja blokira bol u određenom dijelu tijela. Anesteziologija takođe obuhvata upravljanje hroničnim bolom, kao i primjenu analgezije tokom porođaja ili drugih invazivnih procedura. \n Cilj anesteziologije je da se osigura da pacijenti budu udobni i bezbolni tokom zahvata, kao i da se minimiziraju rizici i komplikacije povezane s anestezijom. Anesteziolozi igraju ključnu ulogu u timovima za njegu pacijenata, sarađujući sa hirurzima i drugim zdravstvenim radnicima kako bi obezbijedili optimalnu njegu.'},
    { serviceName: "Aritmologija", serviceImage: aritmologija, doctorImage: dejanK, doctorName: "Dr Dejan Kojić", profession: "aritmolog", content: 'Aritmologija je grana kardiologije koja se bavi proučavanjem, dijagnostikom i liječenjem poremećaja srčanog ritma, poznatih kao aritmije. Ova disciplina fokusira se na nepravilnosti u električnim signalima srca koje mogu uzrokovati prebrzi, prespor ili nepravilan rad srca. Aritmolozi koriste napredne dijagnostičke metode, kao što su EKG, holter monitoring i elektrofiziološke studije, kako bi utvrdili tačan uzrok aritmija. Liječenje obuhvata farmakološke terapije, katetersku ablaciju, kao i ugradnju pejsmejkera ili defibrilatora, s ciljem vraćanja normalnog srčanog ritma i prevencije ozbiljnih komplikacija.'},
    { serviceName: "Gastroskopija i kolonoskopija", serviceImage: gastro, doctorImage: slobodanL, doctorName: "Prim. Dr Slobodan Lovrić", profession: "", content: 'Gastroskopija je dijagnostička procedura koja se koristi za pregledivanje jednjaka, želuca i duodenuma (prvog dijela tankog crijeva) pomoću fleksibilnog instrumenta nazvanog gastroskop. Ova procedura omogućava ljekarima da vizualizuju unutrašnjost probavnog trakta i identifikuju razne probleme, uključujući upale, čireve, tumore i druge abnormalnosti. Tokom gastroskopije, pacijent se obično podvrgava lokalnoj anesteziji grla, a postupak se izvodi kroz usta. Gastroskop ima kameru koja prenosi slike na monitor, što omogućava ljekaru da vidi stanje sluznice probavnog sistema.\nU nekim slučajevima, tokom postupka se mogu uzeti biopsije ili ukloniti polipi. Gastroskopija je korisna za dijagnosticiranje simptoma kao što su bol u stomaku, teškoće pri gutanju, neobjašnjivi gubitak težine ili hronične probavne smetnje.\nKolonoskopija je dijagnostička procedura koja se koristi za pregled debelog crijeva (kolona) i donjeg dijela tankog crijeva (ileuma) pomoću fleksibilnog instrumenta nazvanog kolonoskop. Ova procedura omogućava ljekarima da vizualizuju unutrašnjost debelog crijeva i identifikuju različite probleme, kao što su polipi, upale, čirevi ili karcinomi. Tokom kolonoskopije, pacijent obično prolazi kroz pripremu koja uključuje dijetu s niskim sadržajem vlakana i uzimanje laxativa kako bi se osiguralo da je debelo crijevo prazno. Postupak se izvodi kroz anus, a kolonoskop ima kameru koja prenosi slike na monitor, omogućavajući ljekaru da vidi stanje sluznice debelog crijeva. U toku kolonoskopije, mogu se uzeti biopsije ili ukloniti polipi.\nKolonoskopija je posebno važna za rano otkrivanje kolorektalnog karcinoma i može se preporučiti osobama starijim od 50 godina ili onima s povećanim rizikom od oboljenja.'}
  ];
  return (
    <div className="service-container">
      <span className='service-container-header'>Usluge</span>
      <div className="service-grid-container">
        {serviceItems.map((item) => (
          <ServiceContent serviceName={item.serviceName} serviceImage={item.serviceImage} doctorName={item.doctorName} doctorImage={item.doctorImage} profession={item.profession} content={item.content}/>
      ))}
      </div>
    </div>
  );
}

export default Service;
