import { useNavigate } from 'react-router-dom';
import './TeamIntro.css';
import TeamItem from './TeamItem/TeamItem';
import sanjaD from '../../../../images/team/sanja-dabic.png';
import snezanaG from '../../../../images/team/snezana-glisic.png';
import milicaL from '../../../../images/team/milica-lovric.png';
import daliborkaS from '../../../../images/team/daliborka-spasojevic.png';
import biljanaM from '../../../../images/team/biljana-marjanovic.png';
import slobodanL from '../../../../images/team/slobodan-lovric.png';
import milankoM from '../../../../images/team/milanko-maksic.png';
import stankoL from '../../../../images/team/stansko-lazarevic.png';
import srdjanP from '../../../../images/team/srdjan-popovic.png';
import dejanK from '../../../../images/team/dejan-kojic.png';
import igorD from '../../../../images/team/igor-despotovic.png';
import simonaJ from '../../../../images/team/simona-jelacic.png';
import draganaJ from '../../../../images/team/dragana-jekic.png';

const TeamIntro = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/team');

  setTimeout(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, 100); // Mali delay pomaže u Safariju
  };

  const teamItems = [
    { id: "00a6f454", image: milicaL, name: "Prim. dr Milica Lovrić", profession: "internista- kardiolog" },
    { id: "674b9497", image: slobodanL, name: "Prim. dr Slobodan Lovrić", profession: "opšti hirurg"},
    { id: "6ebbb2c2", image: stankoL, name: "dr Stanko Lazarević", profession: "radiolog"},
    { id: "d31ed72f", image: daliborkaS, name: "dr Daliborka Spasojević", profession: "internista- reumatolog"},
    { id: "5780h444", image: biljanaM, name: "Prim. dr Biljana Marjanović", profession: "internista- onkolog"},
    { id: "c9a89783", image: dejanK, name: "dr Dejan Kojić", profession: "aritmolog"},
    { id: "e0e78783", image: igorD, name: 'dr Igor Despotović', profession: 'kardiolog'},
    { id: "66aFd493", image: sanjaD, name: "Prim. dr Sanja Dabić", profession: "anesteziolog"},
    { id: "c1e89973", image: snezanaG, name: "Prim. dr Snežana Glišić", profession: "internista" },
    { id: "6471F4ae", image: milankoM, name: "Prof. dr Milanko Maksić", profession: "vaskularni hirurg"},
    { id: "c0e88783", image: srdjanP, name: "Prof. dr Srđan Popović", profession: "endokrinolog"},
    { id: "e9ee5457", image: draganaJ, name: 'Dragana Jekić', profession: 'medicinski tehničar'},
    { id: "c9e97854", image: simonaJ, name: 'Simona Jelačić', profession: 'medicinski tehničar'}
  ];

  return (
    <div className="team-intro-container">
      <span className='tema-text'>Naš Tim</span>
      <button className='team-details-button'
        onClick={handleClick}
      >Detaljnije</button>
      <div className='team-grid'>
        {teamItems.map((item) => (
          <TeamItem id= {item.id} image={item.image} name={item.name} profession={item.profession}/>
        ))}
      </div>
    </div>
  );
}

export default TeamIntro;
