import './CareerBlueContent.css';

const CareerBlueContent = () => {
  return (
    <div className="career-content-container">
      <div className="career-values">
        <p className='career-values-header'>Rad u "Mojoj ordinaciji"</p>
        <p className='career-values-text'>Rad u našoj ambulanti pruža priliku za profesionalni razvoj u dinamičnom i podržavajućem okruženju. Naši zaposleni imaju pristup najsavremenijim dijagnostičkim metodama i tehnologijama, uz podsticaj na kontinuiranu edukaciju i timsku saradnju. Fokusirani smo na pružanje najvišeg kvaliteta nege pacijentima, uz poštovanje etičkih standarda i medicinskih protokola.</p>
      </div>
    </div>
  );
}

export default CareerBlueContent;