import './Footer.css';
import logo from '../../images/logo.png';
import facebook  from '../../images/footer/facebook.png'
import instargram  from '../../images/footer/instagram.png'
import { useNavigate } from 'react-router-dom';

const Footer = ({ activeIndex, setActiveIndex }: { activeIndex: number | null; setActiveIndex: (index: number) => void }) => {
  const navigate = useNavigate();

  const handleImageClick = () => {
    navigate('/');
    setActiveIndex(-1); // Set the active index to the clicked element

    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 100); // Mali delay pomaže u Safariju
  };

  const handleClick = (index: number, path: string) => {
    setActiveIndex(index); // Set the active index to the clicked element
    navigate(path); // Navigate to the specified path

  setTimeout(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, 100); // Mali delay pomaže u Safariju
  };

  const quickLinks = [
    { label: "Usluge", path: "/service" },
    { label: "Naš Tim", path: "/team" },
    // { label: "Iz Našeg Ugla", path: "/blog" },
    { label: "Karijera", path: "/career" },
    { label: "Kontakt", path: "/contact" },
  ];

  return (
    <div className="footer-container">
      <div className='footer-contents'>
        <img className='footer-item-logo'
          src={logo}
          alt='logo'
          onClick={handleImageClick}/>
        <span className='footer-contents-text'>
          Posjedujemo najsavremeniju opremu visokih tehnoloških mogućnosti, koja omogućava brzu i pouzdanu dijagnostiku.
        </span>
      </div>
      <div className='quick-links'>
        {quickLinks.map((item, index) => (
          <span
            className={`quick-link-text`}
            key={index}
            onClick={() => handleClick(index, item.path)}>
            {item.label}
          </span>
        ))}
      </div>
      <div className='links'>
        <a href="https://www.facebook.com/p/Moja-Ordinacija-100063886128777/" target="_blank" rel="noopener noreferrer">
          <img
            src={facebook}
            alt='facebook'
            style={{ cursor: 'pointer', width: '130px'}}
          />
        </a>
        <a href="https://www.instagram.com/mojaordinacija.bijeljina?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target="_blank" rel="noopener noreferrer">
          <img
            src={instargram}
            alt='instagram'
            style={{ cursor: 'pointer', width: '130px' }}
          />
        </a>
      </div>
    </div>
  );
}
  
export default Footer;
  