import './Landing.css';
import About from './segments/About/About';
import Intro from './segments/Intro/Intro';
import AboutIntro from './segments/AboutIntro/AboutIntro';
import ServiceIntro from './segments/ServiceIntro/ServiceIntro';
import TeamIntro from './segments/TeamIntro/TeamIntro';
import CareerIntro from './segments/CareerIntro/CareerIntro';
import ContactIntro from './segments/ContactIntro/ContactIntro';
import arrow from '../../images/arrow-right-white.png';
import { useNavigate } from 'react-router-dom';

const Landing = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/contact'); // Navigate to the specified path

  setTimeout(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, 100); // Mali delay pomaže u Safariju
  };

  return (
    <div className="landing-container">
      <div className='segment-1'>
        <Intro/>
        <div className='about-intro-mobile'>
          <div className='about-intro-mobile-content'>
            <p className='aimc-h'>Lokacija</p>
            <p className='aimc'>Prof. Bakajlića br. 5, Bijeljina</p>
            <p className='aimc-h'>Radno vrijeme</p>
            <p className='aimc'>Pon - Pet, od 9:00h do 17:00h</p>
          </div> 
          <div className='aimc-ki-content'
            onClick={() => handleClick()}>
              <p>Kontakt Info</p>
              <img className='arrow-white'
                src={arrow}
                alt='arrow'
              />
            </div>
        </div>
        <About/>
        <AboutIntro/>
      </div>
      <ServiceIntro/>
      <TeamIntro/>
      <ContactIntro/>
      <CareerIntro/>
    </div>
  );
}

export default Landing;
