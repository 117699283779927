import './WhiteCareerContent.css';

const WhiteCareerContent = () => {
  return (
    <div className="white-career-content-container">
      <div className="career-content-div">
        <p className="career-content-div-header">Razvoj karijere</p>
        <p className="career-content-div-values">U "Mojoj ordinaciji" podstičemo profesionalni rast kroz:</p>
        <ul className="career-list">
          <li>- Kontinuiranu medicinsku edukaciju – u vidu stručnih seminara, radionica i usavršavanja.</li>
          <li>- Mentorski program – gde iskusni lekari pružaju podršku i smernice mlađim kolegama.</li>
          <li>- Mogućnosti za napredovanje – kroz jasno definisane karijerne puteve i evaluaciju postignuća.</li>
        </ul>
      </div>
    </div>
  );
}

export default WhiteCareerContent;