import './AboutIntroContent.css';

interface AboutIntroContentProps {
  icon: string;
  label: string;
  value: string;
}
const AboutIntroContent: React.FC<AboutIntroContentProps> = props => {
  const {icon, label, value} = props;

  return (
    <div className="main-icc">
      <img className='icon-icc' src={icon} alt='icon'></img>
      <div className='text-icc'>
        <span className='label-icc'>{label}</span>
        <span className='value-icc'>{value}</span>
      </div>
    </div>
  );
}

export default AboutIntroContent;
