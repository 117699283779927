import './ServiceIntro.css';
import interna from '../../../../images/services/interna-medicina.png';
import kardiologija from '../../../../images/services/kardiologija.png';
import endokrinologija from '../../../../images/services/endokrinologija.png';
import nefrologija from '../../../../images/services/nefrologija.png';
import reumatologija from '../../../../images/services/reumatologija.png';
import hematologija from '../../../../images/services/hematologija.png';
import aritmologija from '../../../../images/services/aritmologija.png';
import onkologija from '../../../../images/services/onkologija.png';
import vaskularnah from '../../../../images/services/vaskularna-h.png';
import opstaH from '../../../../images/services/opsta-hirurgija.png';
import arrow from '../../../../images/services/arrow-service-details.png'
import { useNavigate } from 'react-router-dom';
import ServiceIntroListContent from './ServiceIntroList/ServiceIntroListContent';

const ServiceIntro = () => {
  const navigate = useNavigate();
  
  const handleClick = () => {
    navigate('/service'); // Navigate to the specified path

  setTimeout(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, 100); // Mali delay pomaže u Safariju
  };

  const serviceItems = [
    { icon: interna, label: "Interna medicina", value: "Interna medicina je grana medicine koja se bavi prevencijom, dijagnostikom i liječenjem bolesti unutrašnjih organa kod odraslih osoba." },
    { icon: kardiologija, label: "Kardiologija", value: "Kardiologija je grana medicine koja se bavi dijagnostikom i liječenjem bolesti srca i krvnih sudova." },
    { icon: vaskularnah, label: "Vaskularna hirurgija", value: "Vaskularna hirurgija je grana medicine koja se bavi dijagnostikom i liječenjem bolesti krvnih sudova, uključujući arterije, vene i limfni sistem."},
    { icon: aritmologija, label: "Aritmologija", value: "Aritmologija je grana kardiologije koja se bavi proučavanjem, dijagnostikom i liječenjem poremećaja srčanog ritma, poznatih kao aritmije."},
    { icon: onkologija, label: "Onkologija", value: "Onkologija je grana medicine koja se bavi dijagnostikom, liječenjem i prevencijom raka."},
    { icon: reumatologija, label: "Reumatologija", value: "Reumatologija je grana medicine koja se bavi dijagnostikom i liječenjem reumatskih bolesti, koje obuhvataju širok spektar poremećaja vezivnog tkiva i zglobova."},
    { icon: endokrinologija, label: "Endokrinologija", value: "Endokrinologija je grana medicine koja se bavi proučavanjem hormona, hormonskih žlijezda i poremećaja povezanih s endokrinim sistemom."},
    { icon: hematologija, label: "Hematologija", value: "Hematologija je grana medicine koja se bavi proučavanjem krvi, krvnih zrnaca i bolesti povezanih s krvlju."},
    { icon: opstaH, label: "Opšta hirurgija", value: "Opšta hirurgija je grana medicine koja se bavi dijagnostikom i hirurškim liječenjem različitih bolesti i povreda."}
  ];

  return (
    <div className='service-intro-container'>
      <div className='service-intro-content'>
        <span className='si-usuge'>Usluge</span>
        <div className='service-list'>
          {serviceItems.map((item) => (
            <div className='service-list-content'> 
              <ServiceIntroListContent icon={item.icon} label={item.label} value={item.value}/>
            </div>
          ))}
        </div>
        <div className='service-details-button'
          onClick={() => handleClick()}>
          <span className='details-text'>
            Detaljnije
          </span>
          <img className='arrow-sd'
            src={arrow}
            alt='arrow'
          />
        </div>
      </div>
    </div>
  );
}

export default ServiceIntro;
