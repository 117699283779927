import './ServiceIntroListContent.css';

interface ServiceIntroListContentProps {
  icon: string;
  label: string;
  value: string;
}
const ServiceIntroListContent: React.FC<ServiceIntroListContentProps> = props => {
  const {icon, label, value} = props;

  return (
    <div className="main-silc">
      <img className='icon-silc' src={icon} alt='icon'></img>
      <span className='label-silc'>{label}</span>
      <span className='value-silc'>{value}</span>
    </div>
  );
}

export default ServiceIntroListContent;
