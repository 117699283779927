import './ContactContent.css';
import calendar from '../../../../images/contact/calendar_clock.png';
import mail from '../../../../images/contact/mail.png';
import phone from '../../../../images/contact/ic_baseline-phone.png';
import location from '../../../../images/contact/location_on.png';

const contactDetails = [
  {logo: phone, label: 'Telefon', value: '+387 66 451 391\n+387 55 242 526'},
  {logo: location, label: 'Lokacija', value: 'Prof. Bakajlića br. 5, Bijeljina'},
  {logo: mail, label: 'Mejl', value: 'mordinacija@gmail.com'},
  {logo: calendar, label: 'Radno vrijeme', value: 'Pon - Pet, od 9:00h do 17:00h'}
]
const ContactContent = () => {
  return (
    <div className="contact-content-container">
      <div className="contact-values">
        <p className='contact-values-header'>Kontakt</p>
        <p className='contact-values-text'>Posjetite nas ili nas kontaktirajte na jedan od sledećih načina</p>
        <div className='contact-grid'>
          {contactDetails.map((item) => (
            <div className='contact-grid-item'>
              <img src={item.logo} alt='logo' className='contact-grid-item-img'/>
              <div className='contact-grid-item-div'>
                <span className='contact-grid-item-label'>{item.label}</span>
                  {item.value.split("\n").map((line, index) => (
                  <span key={index} className='contact-grid-item-values'>
                    {line}
                    <br />
                  </span>
                  ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ContactContent;