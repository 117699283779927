import './CareerImageContent.css';
import mail_logo from '../../../../images/contact/mail.png'
import phone_logo from '../../../../images/contact/ic_baseline-phone.png'

const CareerImageContent = () => {
  return (
    <div className="career-image-content-container">
      <div className="career-image-content">
        <p className='career-image-content-header'>Pridružite nam se</p>
        <p className='career-image-content-value'>Ako smatrate da posjedujete stručnost, odgovornost i entuzijazam potreban za rad u "Mojoj ordinaciji", pozivamo Vas da nas kontaktirate i saznate više o mogućnostima zaposlenja i prakse. Naš tim rado će odgovoriti na sva Vaša pitanja i pomoći Vam da pronađete odgovarajući pravac u svojoj medicinskoj karijeri.
        Ambulanta interne medicine "Moja ordinacija" – mjesto gdje stručnost i posvećenost pacijentima idu ruku pod ruku.</p>
        <div className='career-image-content-button-div'>
          <div className='career-image-content-phone-button'>
            <img src={phone_logo} alt='logo' className='career-item-img'/>
            <p>+387 66 451 391</p>
          </div>
          <div className='career-image-content-mail-button'>
            <img src={mail_logo} alt='logo' className='career-item-img'/>
            <p>mordinacija@gmail.com</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CareerImageContent;