import './MapContent.css';
import map from '../../../../images/map_web.png';
import mapMobile from '../../../../images/map_mobile.png';
import { useEffect, useState } from 'react';

const MapContent = () => {
  const [mapSrc, setMapSrc] = useState(map);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setMapSrc(mapMobile);
      } else {
        setMapSrc(map);
      }
    };

    // Initial check on mount
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="map-content-container">
     
    </div>
  );
}

export default MapContent;