import './TeamContent.css';

interface TeamContentProps {
  serviceImage: string;
  serviceName: string;
  content: string;
  doctorImage: string;
  doctorName: string;
  profession: string
}

const TeamContent: React.FC<TeamContentProps> = props => {
  const {serviceImage, serviceName, content, doctorImage, doctorName, profession} = props;

  return (
    <div className='team-content-container'>
      <img src={doctorImage} alt='doctor-image' className='team-content-image'></img>
      <div className='team-content-div'>
        <div className='team-content-div-header'>
          <span>{doctorName},&nbsp;{profession}</span>
        </div>
        {content.split("\n").map((line, index) => (
          <span key={index} className='team-content-div-content'>
            {line}
            <br />
          </span>
        ))}
        <div className='team-content-div-profession'>
          <img src={serviceImage} alt='si' className='profession-img'></img>
          <span className='profession-span'>{serviceName}</span>
        </div>

      </div>
    </div>
  );
};

export default TeamContent;
