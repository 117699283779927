import { ResponsivenessManager } from '../../../../utils/ResponsivenessManager';
import { useWindowDimensions } from '../../../../utils/useWindowDimensions';
import IntroNumbers from './components/IntroNumbers/IntroNumbers';
import './Intro.css';
import { useNavigate } from 'react-router-dom';


const Intro = () => {

  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/contact'); // Navigate to the specified path

  setTimeout(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, 100); // Mali delay pomaže u Safariju
  };

  return (
    <div className="intro-item">
      <div className='intro-item-content'>
        <span className='vz'>
          Vaše zdravlje,
        </span>
        <span className='np'>
          naš prioritet
        </span>
        <span className='psn'>
          Posjedujemo najsavremeniju opremu visokih tehnoloških mogućnosti, koja omogućava brzu i pouzdanu dijagnostiku.
        </span>
        <button
          className='b'
          onClick={() => handleClick()}
        >
          Kontaktirajte Nas
        </button>
        <div className='numbers'>
          <IntroNumbers label='Godina iskustva' value={25}/>
          <IntroNumbers label='Stručnjaka' value={15}/>
          <IntroNumbers label='Zadovoljnih pacijenata' value={10000}/>
        </div>
       </div>
    </div>
  );
}

export default Intro;
