import AboutIntroContent from './components/AboutIntroContent/AboutIntroContent';
import './AboutIntro.css';
import calendar from '../../../../images/calendar_clock.png';
import location from '../../../../images/location.png'
import arrow from '../../../../images/arrow-right.png'
import { useNavigate } from 'react-router-dom';

const AboutIntro = () => {
  const navigate = useNavigate();
  
  const handleClick = () => {
    navigate('/contact'); // Navigate to the specified path

  setTimeout(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, 100); // Mali delay pomaže u Safariju
  };

  return (
    <div className='intro-contact-container'>
      <div className='intro-contact-text'>
        <AboutIntroContent icon={location} label='Lokacija' value='Prof. Bakajlića br. 5, Bijeljina'/>
        <AboutIntroContent icon={calendar} label='Radno vrijeme' value='Pon - Pet, od 9:00h do 17:00h'/>
      </div>
      <div className='intro-contact-button'
        onClick={() => handleClick()}
      >
        <span className='intro-contact-ci'>
          Kontakt Info
        </span>
        <img className='arrow'
          src={arrow}
          alt='arrow'
        />
      </div>
    </div>
  );
}

export default AboutIntro;
