import { useEffect, useState } from 'react';
import './IntroNumbers.css';

interface IntroNumbersProps {
  label: string;
  value: number;
}
const IntroNumbers: React.FC<IntroNumbersProps> = props => {
  const [currentNumber, setCurrentNumber] = useState(0);
  const {label, value} = props;

  useEffect(() => {
    let intervalId: NodeJS.Timeout | number;
    const startSpinning = () => {
      intervalId = setInterval(() => {
        setCurrentNumber((prev) => prev + Math.floor(Math.random() * 10 + 1));
      }, 30); // Speed of spinning
    };

    startSpinning();

    // Stop spinning after 1 seconds
    const timeoutId = setTimeout(() => {
      clearInterval(intervalId);
      setCurrentNumber(value); // Set the final value
    }, 1000);

    // Cleanup on component unmount
    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, [value]);

  return (
    <div className="main-in">
      <span className='value-in'>{currentNumber}+</span>
      <span className='label-in'>{label}</span>
    </div>
  );
}

export default IntroNumbers;
