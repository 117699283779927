import CareerBlueContent from './components/CareerBlueContent/CareerBlueContent'
import ImageContent from './components/ImageContent/ImageContent';
import WhiteCarrerContent from './components/WhiteCarrerContent/WhiteCarrerContent';
import './Career.css';
import CareerImageContent from './components/CareerImageContent/CareerImageContent';

const Career = () => {
  return (
    <div>
      <div className='career-container'>
        <CareerBlueContent/>
        <WhiteCarrerContent/>
        <ImageContent/>
      </div>
      <CareerImageContent/>
    </div>

  );
}
  
export default Career;
      