import './Header.css';
import '../Footer/Footer.css';
import logo from '../../images/logo.png';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonImage from '../../images/hamburger.png';
import facebook  from '../../images/footer/facebook.png';
import instargram  from '../../images/footer/instagram.png';

const Header = ({ activeIndex, setActiveIndex }: { activeIndex: number | null; setActiveIndex: (index: number) => void }) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleClick = (index: number, path: string) => {
    document.body.style.overflow = ''; // Vraćanje skrola
    setModalVisible(false);
    setActiveIndex(index); // Set the active index to the clicked element
    navigate(path); // Navigate to the specified path
  };

  const handleImageClick = () => {
    document.body.style.overflow = ''; // Vraćanje skrola
    setModalVisible(false);
    setActiveIndex(-1); // Set the active index to the clicked element
    navigate('/');
  };

  
  const closeModal = () => {
    setModalVisible(false);
    document.body.style.overflow = ''; // Vraćanje skrola
  };

  const openModal = () => {
    setModalVisible(true);
    document.body.style.overflow = 'hidden'; // Onemogućavanje skrola
  };

  const menuItems = [
    { label: "Usluge", path: "/service" },
    { label: "Naš Tim", path: "/team" },
    // { label: "Iz Našeg Ugla", path: "/blog" },
    { label: "Karijera", path: "/career" },
    { label: "Kontakt", path: "/contact" },
  ];

  return (
    <div className="header-container">
      <img className='header-item-logo'
      src={logo}
      alt='logo'
      onClick={handleImageClick}
      />

      <div className="header-item-menu">
        {menuItems.map((item, index) => (
          <span
            className={`menu-text ${activeIndex === index ? 'blue-text' : 'base-text'}`}
            key={index}
            onClick={() => handleClick(index, item.path)}
          >
            {item.label}
          </span>
        ))}
      </div>

      {
        !modalVisible  &&
        <button className="image-button" onClick={openModal}>
        <img src={ButtonImage} alt="Icon" className="button-icon" />
        </button>
      }

     {
      modalVisible &&
      <div className="modal" id="modal">
        <div className="modal-content">
          <img className='header-item-logo-modal'
            src={logo}
            alt='logo'
            onClick={handleImageClick}
          />
          <button className="close-button" onClick={closeModal}>×</button>
          <ul className="nav-routes">
            {menuItems.map((item, index) => (
              <span
                className='modal-text'
                key={index}
                onClick={() => handleClick(index, item.path)}
              >
                {item.label}
              </span>
            ))}
          </ul>
          <div className='links-m'>
            <a href="https://www.facebook.com/p/Moja-Ordinacija-100063886128777/" target="_blank" rel="noopener noreferrer">
              <img
                src={facebook}
                alt='facebook'
                style={{ cursor: 'pointer', width: '130px'}}
              />
            </a>
            <a href="https://www.instagram.com/mojaordinacija.bijeljina?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target="_blank" rel="noopener noreferrer">
              <img
                src={instargram}
                alt='instagram'
                style={{ cursor: 'pointer', width: '130px' }}
              />
            </a>
          </div>
        </div>
      </div>
     }
    </div>
    
  );
};

export default Header;
