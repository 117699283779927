import './Team.css';
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import interna from '../../images/services/dark-versions/interna.png';
import anestezija from '../../images/services/dark-versions/anestezija.png';
import aritmologija from '../../images/services/dark-versions/aritmologija.png';
import endokrina from '../../images/services/dark-versions/endokrinologija.png';
import gastro from '../../images/services/dark-versions/gastro.png';
import hematologija from '../../images/services/dark-versions/hematologija.png';
import kardiologija from '../../images/services/dark-versions/kardiologija.png';
import nefrologija from '../../images/services/dark-versions/nefrologija.png';
import onkologija from '../../images/services/dark-versions/onkologija.png';
import opstah from '../../images/services/dark-versions/opstah.png';
import ortopedija from '../../images/services/dark-versions/ortopedija.png';
import radiologija from '../../images/services/dark-versions/radiologija.png';
import reumatologija from '../../images/services/dark-versions/reumatologija.png';
import urologija from '../../images/services/dark-versions/urologija.png';
import vaskularnah from '../../images/services/dark-versions/vaskularnah.png';
import TeamContent from "./components/TeamContent/TeamContent";
import sanjaD from '../../images/team/sanja-dabic.png';
import snezanaG from '../../images/team/snezana-glisic.png';
import igorD from '../../images/team/igor-despotovic.png';
import milicaL from '../../images/team/milica-lovric.png';
import daliborkaS from '../../images/team/daliborka-spasojevic.png';
import biljanaM from '../../images/team/biljana-marjanovic.png';
import slobodanL from '../../images/team/slobodan-lovric.png';
import milankoM from '../../images/team/milanko-maksic.png';
import dejanK from '../../images/team/dejan-kojic.png';
import stankoL from '../../images/team/stansko-lazarevic.png';
import simonaJ from '../../images/team/simona-jelacic.png';
import srdjanP from '../../images/team/srdjan-popovic.png';
import draganaJ from '../../images/team/dragana-jekic.png';

const teamItems = [
  { id: "00a6f454", serviceImage: kardiologija, serviceName: 'Kardiologija', doctorName: 'Prim. dr Milica Lovrić', profession: 'internista- kardiolog', doctorImage: milicaL, content: `Rođena 1967., u Bijeljini gdje je završila osnovnu i srednju školu. Medicinski fakultet završila u Tuzli. Specijalizaciju iz Interne medicine završila na VMA 1998., a subspecijalizaciju iz kardiologije završila na Medicinskom fakultetu u Beogradu 2002.\nZaposlena u JZU „Sveti vračevi“ u Bijeljini, obavlja funkciju šefa Odsjeka kardiologije. Godine 2008. od Udruženja kardiologa RS proglašena je za Nacionalnog kardiologa.\nMinistarstvo zdravlja i socijalne zaštite RS godine 2013. dodjeljuje joj zvanje Primarijus. Od strane Komore doktora medicine RS godine 2013. dobija Povelju za najuspješnijeg ljekara Semberijske regije. Od Skupštine grada Bijeljina dobitnik Zlatne povelje „Knez Ivo od Semberije” za dobrinos razvoju zdravstva, a posebno za razvoj kardiologije u gradu Bijeljina.\nČlan kardiološkog tima u projektima Akademije nauka i umjetnosti RS/Akutni koronarni sy u RS. Član Udruženja kardiologa Srbije/član upravnog, organizacionog odbora i više radnih grupa u Udruženju/Predsjednik Radne grupe za hipertenziju, član Radne grupe za akutna stanja u kardiologiji, srčanu insuficijenciju i ehokardiogafiju. Predsjednik Udruženja kardiologa RS.` },
  { id: "674b9497", serviceImage: opstah, serviceName: 'Opšta hirurgija', doctorName: 'Prim. dr Slobodan Lovrić', profession: 'opšti hirurg', doctorImage: slobodanL, content: `Rođen 1964. godine u Tuzli. Medicinski fakultet završio u Tuzli 1991. godine. Specijalizaciju iz Opšte hirurgije zavrsio 1998. godine u Beogradu. Edukaciju iz endoskopskih procedura zavrsio na VMA u Beogradu.\nNosilac Zlatne značke grada Bijeljina za izuzetan doprinos na polju zdravstva. 2010. godine promovisan u zvanje Primarijusa. Načelnik odjeljenja za hirurške grane u JZU Bolnica „Sveti Vračevi“ Bijeljina.` },
  { id: "d31ed72f", serviceImage: reumatologija, serviceName: 'Reumatologija', doctorName: 'dr Daliborka Spasojević', profession: 'internista- reumatolog', doctorImage: daliborkaS, content: `Rođena u Bijeljini gdje je završila osnovno i srednje obrazovanje. Diplomirala je na Medicinskom fakultetu Univerziteta u Novom Sadu, a specijalisticke studije iz interne medicine pohađala je na Medicinskom fakultetu Univerziteta Istočno Sarajevo u Foči i Medicinskom fakultetu Univerziteta u Beogradu, te dobila zvanje specijaliste interne medicine.\nZvanje subspecijaliste reumatologije stekla je na Institutu za reumatologiju u Beogradu 2020. godine. Od 2009. godine radi u JZU Bolnica" Sveti Vračevi " na odjeljenju za internističke grane, odsjek reumatologije, a prije toga je radila u DZ Bijeljina u Službi porodične medicine sa završenom edukacijom iz ove oblasti.\nUčesnik je brojnih kongresa, simpozijuma, radionica i aktivno prati novine u reumatologiji i internoj medicini.\nUdata, majka dva sina.` },
  { id: "6471F4ae", serviceImage: vaskularnah, serviceName: 'Vaskularna hirurgija', doctorName: 'dr Milanko Maksić', profession: 'vaskularni hirurg', doctorImage: milankoM, content: `Rođen je 2. oktobra 1965. godine u Drvaru.\nZavršio Medicinski fakultet u Banjaluci 1989. godine. Specijalizirao Opštu hirurgiju 1997. godine, a subspecijalizaciju iz vaskularne hirurgije 2002. godine.\nNačelnik službe za vaskularnu hirurgiju UKC Republike Srpske. Doktor medicinskih nauka, specijalista opšte i vaskularne hirurgije. Radio u bolnici u Drvaru (1989.-1992.), a od 1992. do danas na Klinici za opštu i abdominalnu hirurgiju, Služba za vaskularnu hirurgiju KC Republike Srpske.\nČlanstvo u naučnim i stručnim organizacijama ili udruženjima:\n1.	Član Evropskog udruženja vaskularnih hirurga,\n2. Član Udruženja flebologa Srbije,\n3.	Član Udruženja Vaskularnih hirurga Srbije,\n4.	Član Udruženja hirurga Republike Srpske` },
  { id: "5780h444", serviceImage: onkologija, serviceName: 'Onkologija', doctorName: 'Prim. dr Biljana Marjanović', profession: 'internista- onkolog', doctorImage: biljanaM, content: `Diplomirala je na Medicinskom fakultetu Univerziteta u Banja Luci, a specijalističke studije iz interne medicine pohađala je na Medicinskom fakultetu Univerziteta u Beogradu, te dobila zvanje specijaliste interne medicine 2008. godine.\nZvanje subspecijaliste onkologa stekla je na Medicinskom fakultetu Univerziteta u Beogradu 2010. godine. Od 2001. godine radi u JZU Bolnica "Sveti Vračevi" na odjeljenju za internističke grane, a od 2011. godine na odsjeku za onkologiju.\nUčesnik je brojnih kongresa, simpozijuma, radionica i aktivno prati novine iz oblasti onkologije i interne medicine. Šefica odsjeka za onkologiju u JZU Bolnica „Sveti Vračevi“ Bijeljina.` },
  { id: "6ebbb2c2", serviceImage: radiologija, serviceName: 'Radiologija', doctorName: 'dr Stanko Lazarević', profession: 'radiolog', doctorImage: stankoL, content: `Dr Stanko Lazarević, specijalista radiologije rođen je 12.1.1976. godine u Bijeljini, gdje je završio osnovnu i srednju školu. Osnovne medicinske studije završio je u Beogradu na Medicinskom fakultetu Univerziteta u Beogradu 2003. godine. Specijalističke studije iz oblasti radiologije obavio je u Beogradu u periodu od 2006. godine do 2010. godine, gdje je i položio specijalistički ispit.\nNakon završetka osnovnih studija u periodu 2004. / 2005. godina, radio je u Zdravstvenom centru Zvornik, a od 2005. godine pa do 2020. godine u JZU „Sveti Vracevi" Bijeljina, nakon čega radi u privatnom sektoru.` },
  { id: "66aFd493", serviceImage: anestezija, serviceName: 'Anestezija', doctorName: 'Prim. dr Sanja Dabić', profession: 'anesteziolog', doctorImage: sanjaD, content: "Rođena je 15.02.1970. godine.\nDiplomirala je na Medicinskom fakultetu u Nišu 1998. godine. Specijalizaciju iz anesteziologije sa reanimatologijom završila je 2006. godine na Medicinskom fakultetu, Univerzitet u Beogradu.\nU periodu od 2000. do 2020. godine radila je u JZU „Sveti vračevi“ u Bijeljini, gdje je radila preoperativnu procjenu i pripremu pacijenata za hirurške zahvate, izvođenje opšte i regionalne anestezije, primjenu centralnih i perifernih blokova, postoperativno praćenje i reanimacija pacijenata. Rad na odjeljenju intezivne njege.\nOd 2020. godine do danas radi u JZU Bolnici u Brčkom kao anesteziolog- reanimatolog.\n Član je Komore doktora medicine Rebublike Srpske, kao i Udruženja anesteziologa i reanimatologa Republike Srpske (UARIRS)." },
  { id: "c9a89783", serviceImage: aritmologija, serviceName: 'Aritmologija', doctorName: 'dr Dejan Kojić', profession: 'aritmolog', doctorImage: dejanK, content: `Radno mesto / Radno iskustvo:\n•	2007. - IKVB Dedinje\n•	2002. - 2006. - Vojnomedicinska akademija\nObrazovanje:\n•	2013. - Subspecijalizacija iz kardiologije - Medicinski fakultet Univerziteta u Beogradu\n•	2002. - 2006. - Specijalizacija iz interne medicine - Vojnomedicinska akademija\n•	1992. - 1999. - Medicinski fakultet Univerziteta u Beogradu\nViše koautorskih radova u domaćim stručnim časopisima.\nUdruženje kardiologa Srbije European Society of Cardiology - ESCEuropean Heart Rhythm Association - EHRA\nOd 2008. godine se bavi interventnom elektrofiziologijom srca, kateterskim ablacijama srčanih aritmija i implantacijama pejsmejkera (standardni antibradikardni pejsmejkeri, resinhronizacioni pejsmejkeri-CRT i kardioverter defibrilatori-ICD)\n2013 Studijski boravak, Royal Brompton Hospital, London, UK2013 Studijski boravak, Asklepios St Georg Klinik, Hamburg, Germany`},
  { id: "c0e88783", serviceImage: endokrina, serviceName: 'Endokrinologija', doctorName: 'Prof. dr Srđan Popović', profession: 'endokrinolog', doctorImage: srdjanP, content: "je specijalista interne medicine, endokrinolog iz Beograda.\nSvoje znanje stečeno na Medicinskom fakultetu u Beogradu nadograđivao je u bolnicama St. Frances Lab Hammersmith i St. Bartholomew’s u Londonu. Zaposlen je u KBC Srbije i redovni je profesor na Medicinskom fakultetu univerziteta u Beogradu.\nProf. dr Popović obavlja sledeće preglede:\n•	Sve endokrinološke preglede (pretežno se bavi dijabetesom i njegovim hroničnim komplikacijama, kao i autonomnoj neuropatiji nastaloj usled dijabetesa),\n•	preglede i tretman kod problema steriliteta,\n•	preglede i tretman patologije štitne žlezde, hipofize, nadbubrežne žlezde, policističnih jajnika, insulinske rezistencije i gojaznosti.\nUčestvovao je na većini evropskih i svetskih kongresa iz oblasti endokrinologije i dijabetesa. Prof. dr Srđan Popović nacionalni je ambasador rane insulinizacije klinike Mayo." },
  { id: "e0e78783", serviceImage: kardiologija, serviceName: 'Kardiologija', doctorName: 'dr Igor Despotović', profession: 'kardiolog', doctorImage: igorD, content: `Rođen 25.2.1978. godine u Sarajevu.\nZavršen Medicinski fakultet u Beogradu dana 28.02.2006. i stečeno zvanje Doktora medicine.\nZavršena specijalizacija i položen specijalistički ispit iz interne medicine na Medicinskom fakultetu u Beogradu. Završen kurs ultrazvuka abdomena 2008.g. u trajanju od 3 meseca na KCS kod Prof. Dr Mirjane Perišić sa overenim sertifikatom.\nU decembru 2018.g. završen kurs i položen ispit sa dobijanjem evropskog sertifikata iz "Basic life support (BLS)" i "Advanced life support (ALS)" organizovanim od strane Resuscitacionog Saveza Srbije.\nU decembru 2018.g. upisao subspecijalizaciju iz kardiologije na Medicinskom fakultetu Univerzitetu u Beogradu, nastavna baza "IKVB Dedinje" i pod mentorstvom načelnika invazivne kardiološke dijagnostike Doc. dr Milanom Dobrićem 28.12.2023.g. položio subspecijalistički ispit.\nSavladane dijagnostičke tehnike: ergometrija, koronarografija, EHO srca, očitavanje 24h Holtera EKG i 24h AMP.`},
  { id: "c1e89973", serviceImage: interna, serviceName: 'Interna medicina', doctorName: 'Prim. dr Snežana Glišić', profession: 'internista', doctorImage: snezanaG, content: "Prim. dr Snežana Glišić je rođena 1954. godine u Bijeljini, gdje je završila Osnovnu školu i Gimnaziju. Medicinski fakultet upisala je u Beogradu 1972. godine, diplomirala 1977. i iste godine se zaposlila u Medicinskom centru Bijeljina kao ljekar opšte prakse.\nOd 1980. godine radi na Internom odjeljenju, a 1982. godine dobija  specijalizaciju interne medicine koju završava u Beogradu 1986. godine, te od tada kao internista radi u Internističkoj službi Opšte bolnice Bijeljina. Od 2000. godine postala je šef  odsjeka intenzivne njege internističke službe , a od 2005. godine načelnik Internističke službe.\nOdlukom Medicinskog fakulteta Beograd 2000. godine određena  je za mentora u Opštoj bolnici Bijeljina za specijalizante interne medicine.\nPosle završene obuke u KC Beograd, dobila je sertifikat za samostalni rad Color-doppler ultrasonografije, koju obavlja u Opštoj bolnici Bijeljina uporedo sa poslom interniste u Internom odjeljenju.\nOdlukom Ministarstva zdravlja i socijalne zaštite RS 2004. godine dodijeljeno joj je počasno zvanje Primarijus." },
  { id: "c9e97854", serviceImage: aritmologija, serviceName: 'Medicinski tehničar', doctorName: 'Simona Jelačić', profession: 'medicinski tehničar', doctorImage: simonaJ, content: "-" },
  { id: "e9ee5457", serviceImage: aritmologija, serviceName: 'Medicinski tehničar', doctorName: 'Dragana Jekić', profession: 'medicinski tehničar', doctorImage: draganaJ, content: "-" }

];

const Team = () => {
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    // Scroll to the element with the matching ID
    const element = document.getElementById(id!);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [id]);

  return (
    <div className='team-container'>
      <span className="team-header">Naš Tim</span>
      {teamItems.map((item) => (
        <div
          key={item.id}
          id={item.id}
          className="team-list"
        >
          <TeamContent serviceImage={item.serviceImage} serviceName={item.serviceName} doctorName={item.doctorName} doctorImage={item.doctorImage} profession={item.profession} content={item.content}/>
        </div>
      ))}
    </div>
  );
};

export default Team;
