import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Landing from './screens/Landing/Landing';
import Service from './screens/Service/Service';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Blog from './screens/Blog/Blog';
import Career from './screens/Career/Career';
import Contact from './screens/Contact/Contact';
import Team from './screens/Team/Team';
import BlogItem from './screens/Blog/components/BlogItem/BlogItem';
import { useState } from 'react';

const App = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  return (
    <Router>
      <div style={{ display: 'flex', flexDirection: 'column'}}>
        <Header activeIndex={activeIndex} setActiveIndex={setActiveIndex}/>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/service" element={<Service />} />
          {/* <Route path="/blog" element={<Blog />} /> */}
          <Route path="/career" element={<Career />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/team" element={<Team />} />
          <Route path="/team/:id" element={<Team />} />
          {/* <Route path="/blog/details" element={<BlogItem/>} /> */}
        </Routes>
        <Footer activeIndex={activeIndex} setActiveIndex={setActiveIndex}/>
      </div>
    </Router>
  );
}

export default App;
