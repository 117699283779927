import { useNavigate } from 'react-router-dom';
import './CareerIntro.css';

const CareerIntro = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/career');

  setTimeout(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, 100); // Mali delay pomaže u Safariju
  };
  return (
    <div className="career-intro-container">
      <span className='rsn'>Radite s nama</span>
      <span className='pop'>Pogledajte otvorene pozicije, prijavite se i postanite dio tima Moja Ordinacija.</span>
      <button 
        className='career-button'
        onClick={handleClick}>Karijera</button>
    </div>
  );
}

export default CareerIntro;
