import './ServiceContent.css';

interface ServiceContentProps {
  serviceImage: string;
  serviceName: string;
  content: string;
  doctorImage: string;
  doctorName: string;
  profession: string;
}

const ServiceContent: React.FC<ServiceContentProps> = props => {
  const {serviceImage, serviceName, content, doctorImage, doctorName, profession} = props;

  return (
    <div className="service-grid-item">
      <h2 className="grid-title">
       <img src={serviceImage} alt='si' className='heading-img'/>
       {serviceName}
      </h2>
      <p className="grid-description">
        {content.split("\n").map((line, index) => (
          <span key={index}>
            {line}
            <br />
            <br />
          </span>
        ))}
      </p>
      <p className="grid-doctor">
        <img src={doctorImage} alt='di' className='sgc-footer-img'/>
        <div className='sgc-footer-profession'>
          <em>{doctorName},&nbsp;</em>
          <em>{profession}</em>
        </div>
      </p>
    </div>
  );
}

export default ServiceContent;
