import './TeamItem.css';

interface TeamItemProps {
  id: string;
  image: string;
  name: string;
  profession: string;
}

const TeamItem: React.FC<TeamItemProps> = props => {
  const {id, image, name, profession} = props;

  return (
    <div
    key={id}
    className="team-grid-item"
    style={{ backgroundImage: `url(${image})` }}
  >
  <a href={`/team/${id}`} className="hover-overlay">
    <span>{name} <br /> {profession}</span>
  </a>
</div>
  );
}

export default TeamItem;
