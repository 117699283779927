import ContactContent from './components/ContactContent/ContactContent';
import MapContent from './components/MapContent/MapContent';
import WhiteContent from './components/WhiteContent/WhiteContent';
import './Contact.css';

const Contact = () => {
  return (
    <div className='contact-container'>
      <ContactContent/>
      <WhiteContent/>
      <MapContent/>
    </div>
  );
}
  
export default Contact;
      