import { useNavigate } from 'react-router-dom';
import './ContactIntro.css';

const ContactIntro = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/contact');

  setTimeout(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, 100); // Mali delay pomaže u Safariju
  };
  return (
    <div className="contact-intro-container">
      <span className='pr'>Želite da zakažete pregled ili imate dodatna pitanja?</span>
      <span className='mdn'>Možete da nas kontaktirate putem telefona, mejla ili na adresi Prof. Bakajlića br. 5, Bijeljina.
      Stojimo Vam na raspologanju.</span>
      <button 
        className='contact-button'
        onClick={handleClick}>Kontaktirajte Nas</button>
    </div>
  );
}

export default ContactIntro;
