import './About.css';
import aboutdoc from '../../../../images/about-doctors.png';
import elipse from '../../../../images/ellipse.png';

const About = () => {

  const aboutItems = [
    "interniste",
    "kardiologa",
    "aritmologa",
    "nefrologa",
    "reumatologa",
    "onkologa",
    "endokrinologa",
    "hematologa",
    "hirurga",
    "vaskularnog hirurga",
    "ortopeda",
    "urologa",
    "radiologa",
    "anesteziologa"
  ];

  return (
    <div className="about-container">
      <div className='about-content'>
          <img className='imgdoctors'
            src={aboutdoc}
            alt='doctors'
          />
        <div className='about-text'>
          <span className='ksm'> Ko smo mi?</span>
          <span className='sc'> Zdravstvena ustanova “Moja ordinacija” nudi širok spektar pregleda iz različitih oblasti medicine.</span>
          <div className='about-section'>
            <span className='sc'> Kod nas možete obaviti pregled kod</span>
            <div className='section'>
              {aboutItems.map((item) => (
                <div className='section-content'> 
                  <img 
                    className='img-elipse'
                    src={elipse}
                    alt='elipse'/>
                  <span className="scs">
                    {item}
                  </span>
                 </div>
              ))}
            </div>
          </div>
          <span className='about-end'>
            Takođe možete uraditi gastroskopiju i kolonoskopiju sa ili bez anestezije, male hirurške intervencije, kao i razne dijagnostičko-terapijske procedure iz oblasti kardiologije.
          </span>
        </div>
      </div>
    </div>
  );
}

export default About;
